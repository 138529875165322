<template>

<div class="main">

  <div class="header">

  </div>

  <h1 class="align-self-center">قیمت رمز ارزها</h1>

  <div class="market-container">
    <div class="title flex-wrap">
      <div class="right">
        <h2> نگاه کلی به بازار </h2>
        <span> در ۲۴ ساعت گذشته </span>
      </div>


<div class="input-container">
  <img src="../../assets/mag.png" alt="">
  <input type="text" placeholder="جستجو در رمز ارزها" v-model="regex" >
</div>
    </div>
    <div class="table">
      <table>
        <thead>
        <tr>
          <td style="text-align:start;"> رمز ارز </td>
          <td class="remove2"> قیمت لحظه ای </td>
          <td> تغییرات </td>
          <td class="remove"> حجم </td>
          <td style="text-align:center;" class="remove"> نمودار </td>
          <td style="text-align:end;display:flex;justify-content:center">
            <div class="pricebtns">
              <button @click="toman=true" :class="{'select':toman}" class="toman"> تومان </button>
              <button @click="toman=false" :class="{'select':!toman}" class="tether"> تتر </button>
            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr @click.prevent="" class="coin-row" v-for="(coin,index) in getCrypto()" :key="index">
          <td>
            <div class="coin">
              <img :src="'/coins/'+$coin2Snake[coin.relatedCoin]+'.png'" alt="">
              <div class="unit remove2">
                                    <span class="faunit">
                                        {{$coinLabel[coin.relatedCoin]}}
                                    </span>
                <span class="enunit">
                                        {{coin.relatedCoin}}
                                    </span>
              </div>
            </div>
          </td>
          <td class="remove2">
            {{$toLocal(coin.lastPrice,(toman ? 0:2))}}
          </td>
          <td>
                            <span :class="coin.last24HourChange > 0 ? 'red rotate':'fail'">
                                {{$toLocal(coin.last24HourChange,2)}}%
                                <inline-svg :class="coin.last24HourChange > 0 ? 'rotate':'red'" :src="require('../../assets/Icons/arrow.svg')" />
                            </span>
          </td>
          <td class="remove"> {{$toLocal(coin.last24HourVolume,$decimal[$coin2Snake[coin.relatedCoin]])}} </td>
          <td class="remove">
            <MarketChartApex :data="coin.lastWeekData" :option="coin.last24HourChange > 0 ? true : false" />
          </td>
          <td>
            <div class="td-btn">
              <button @click.prevent="goto(coin.relatedCoin)" class="btn-secondary-outline submit"> معامله </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>
</template>

<script>
import MarketChartApex from '../tools/MarketChartApex.vue'
export default {
  name:'Market',
  components:{
    MarketChartApex
  },

  data() {
    return {
      coinsToman:[],
      coinsTether:[],
      pricetype:'TOMAN',
      regex:'',
      toman:false,
      coinn:{
        buyPrice: 1759047.192,
        id: null,
        last24HourChange: 0.62,
        last24HourHighestPrice: 0,
        last24HourOrders: 0,
        last24HourTrades: 0,
        last24HourVolume: 0,
        lastPrice: 1752912,
        lastPriceOfYesterday: 0,
        lastTradeCreatedDateTime: null,
        lastWeekData: [100,200,10000,1200,4500,6700,10,40,1],
        lastWeekHighestPrice: 0,
        marketType: "LITE_COIN_TOMAN",
        maxPrice: 1792700,
        meanPriceOfLast24Hour: 0,
        minPrice: 1708224,
        relatedCoin: "LTC",
        sellPrice: 1746776.808,
      }

    }
  },

  mounted(){
    this.getCoins();
  },
  computed:{
    coins(){
      let coinss
      if(this.toman){
        coinss=this.coinsToman
      }else{
        coinss=this.coinsTether.filter(a => a.relatedCoin !== 'USDT')
      }
      return coinss
    }
  },
  methods:{
    async getCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/trades/market-info',{
        params: {
          weeklyDataIncluded: true,
        }
      })
      this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN'))
      this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER'))
      console.log(this.coinsToman,this.coinsTether);
    },


    getCrypto() {
      return this.coins.filter((crypto) => {
        if (this.toman == true && crypto.marketType.includes('TOMAN') && (this.$coinLabel[crypto.relatedCoin].includes(this.regex) || this.$coin2Snake[crypto.relatedCoin].toLowerCase().includes(this.regex.toLowerCase()) || crypto.relatedCoin.includes(this.regex.toUpperCase()))) {
          return crypto
        } else {
          if (this.toman == false && crypto.marketType.includes('TETHER') && (this.$coinLabel[crypto.relatedCoin].includes(this.regex) || this.$coin2Snake[crypto.relatedCoin].toLowerCase().includes(this.regex.toLowerCase()) || crypto.relatedCoin.includes(this.regex.toUpperCase()))) {
            return crypto
          }
        }
      })
    },


    goto(coin){
      let selectedCoin =this.$coin2Snake[coin]
      if(this.toman){
        this.$router.push(`/advancetrade/TOMAN/${selectedCoin}`)
      }

      this.$router.push(`/advancetrade/TETHER/${selectedCoin}`)



    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  margin-top: 60px;
  width: 100%;
  min-height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: url('../../assets/public-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.main{
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 10%;
}
h1{
  align-self: center;
}
.input-container{
  display: flex;
  align-items: center;
  gap: 10px;
  height: 52px;
  padding: 10px;
  border-radius: 8px 8px 0px 0px;
}
.coin-row:hover{
  background: #F0F0F5;
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 8px;
  cursor: pointer;
}
.enunit{
  color: var(--a-hover) !important;
  font-weight: 400 !important;
  font-size: clamp(10px,1vw,12px) !important;
}
.marketsend{
  color: var(--primary);
  align-self: flex-end;
  margin-left: 100px;
}
.td-btn{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  button{
    width: 100%;
    max-width: 130px;
  }
}
.coin{
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  img{
    width: 36px;
    height: 36px;
  }
  .unit{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
}
.select{
  color: var(--secondary) !important;
  background: #DEDEDE !important;

}
.pricebtns{
  display: flex;
  flex-direction: row;
  padding: 6px;
  width: 100%;
  height: 48px;
  box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
  border-radius: 4px;
  background: #F8F8F8;
  button{
    transition: 1s ease all;
    color: var(--black);
    font-weight: 500;
    font-size: clamp(10px,1vw,12px);
    border-radius: 4px;
    width: 100% !important;
    max-width: 80px !important;
    background: transparent;
  }
}
.submit{
  height: 38px;
}
.table{
  height: 100%;
  background: #F8F8F8;
  padding: 12px 14px;
  border-radius: 8px;
  width: 100%;
  table{
    border-collapse: collapse;
    background: transparent;
    width: 100%;
    tr{
      border-bottom: 1px solid #F0F0F0;
      height: 76px;
    }
    thead{
      height: 37px;
      td{
        text-align: start;
        font-weight: 700;
        font-size: clamp(14px,2vw,16px);
        color: var(--gray-light);
      }
    }
  }
}
.title{
  display: flex;
  justify-content: space-between;

  .right{
    display: flex;
    align-items: center;
    gap: 4px;
    h2{
      font-weight: 700;
      color: var(--secondary);
      font-size: clamp(16px,2vw,20px);
    }
    span{
      font-weight: 700;
      font-size: clamp(12px,2vw,14px);
      color: var(--gray-light);
    }
  }
  //display: flex;
  flex-direction: row;
  width: 100%;
  //justify-content: start;
  align-items: center;
  column-gap: 8px;
}
.market-container{
  //padding: 40px 120px;

  border-radius: 8px;
  padding:0  10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 36px;
  justify-content: center;
  //background: url('../../assets/bubble/charts.png');
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: center;
}
@media only screen and(max-width:1000px){
  .market-container{
    padding: 20px;
    align-items: center;
    justify-content: center;
  }
  .remove{
    display: none;
  }
}
@media (max-width:800px){
  .main{
    margin-top: 0px;
  }
  .remove2{
    display: none !important;
  }
}
.img{
display: none;
background-image: url("../../assets/public-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>